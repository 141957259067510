import { TYPES } from '@buildable-microservices/components';
import config from '@buildablecore/src/config';
import { useEffect } from 'react';

const NotFoundPage = () => {
  useEffect(() => {
    window.location.href = `${config.microservicesUrl}${TYPES.ROUTES[404]}`;
  }, []);

  return <></>;
};

export default NotFoundPage;
